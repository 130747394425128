import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AutoOrder from '../pageBuilders/autoOrder';
import Layout from '../components/layout';
import { GetHomeDataQuery } from '../../graphql-types';
import { WithInitialLoaderDecorator } from '../components/withInitialLoaderDecorator';
import * as autoOrderStyle from '../pageBuilders/autoOrder.module.css';

const SEO = {
  'title': 'Home',
  'author': 'Deutsch LA',
  'keywords': 'Deutsch LA',
};

const GET_HOME_DATA = graphql`
  query GetHomeData {
    allNodeBodyBuilder(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteBodyBuilderItems
    }
    allNodeMasthead(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteMastheadsFields
    }
    allNodeTout(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteToutFields
    }
    allNode2UpGrid(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...Complete2upGridFields
    }
    allNode3UpInformative(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...Complete3UpInformativeFields
    }
    allNodeToutText(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteToutTextFields
    }
    allNodeContentAdvertise(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteContentAdvertiseFields
    }
    allNodeQuote(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteQuoteFields
    }
    allNodeToutSimple(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteToutSimpleFields
    }
    allNodeSimpleMedia(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteSimpleMediaFields
    }
    allNodeMediaGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteMediaGridFields
    }
    allNodeCarousel(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteCarouselFields
    }
    allNodeArticleTout(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteArticleToutFields
    }
    allNodeBodyContent(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteBodyContentFields
    }
    allNodeDownloadableContent(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteDownloadableContentFields
    }
    allNodeQuickLi(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteQuickLinksFields
    }
    allNodeNewsGrid(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteNewsGridFields
    }
    allNodeArticlesGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteArticlesGridFields
    }
    allNodeTout5050(filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }) {
      ...CompleteTout5050Fields
    }
    allNodeMasthead2022(
      filter: { relationships: { field_page_reference: { name: { eq: "Home" } } } }
    ) {
      ...CompleteMasthead2022Fields
    }
  }
`;

const IndexPage: React.FC = () => {
  const data = useStaticQuery<GetHomeDataQuery>(GET_HOME_DATA);
  return (
    <Layout seo={SEO}>
      <WithInitialLoaderDecorator>
        <AutoOrder
          data={data}
          className={`${autoOrderStyle.autoOrderPageHeight} pb-18`}
          loadMore
          id="home"
        />
      </WithInitialLoaderDecorator>
    </Layout>
  );
};

export default IndexPage;
