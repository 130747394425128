import React from 'react';

const InitialLoader: React.FC = () => (
  <div id="___initialLoader" style={{ transition: 'opacity 0.5s ease-in' }}></div>
);

const WithInitialLoaderDecorator: React.FC<any> = (props) => {
  return (
    <>
      <InitialLoader />
      <div id="___wrappedLoadingComponent" className="opacity-0">
        {props.children}
      </div>
    </>
  );
};

const withInitialLoaderDecorator = (WrappedComponent: React.FC<any>): React.FC<any> => {
  const WrapperInitialLoader: React.FC<any> = (props) => (
    <WithInitialLoaderDecorator>
      <WrappedComponent {...props} />
    </WithInitialLoaderDecorator>
  );
  return WrapperInitialLoader;
};

export { WithInitialLoaderDecorator, withInitialLoaderDecorator };
